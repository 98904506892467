import React, {Fragment}  from "react";
import {fetchVisitsByQuery} from "../../../../actions/visit_actions";
import {connect} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import {showErrorGrowl} from "../../../../actions/msg_actions";
import {DateUtils} from "../../../../actions/common_utils";
import paginationFactory from "react-bootstrap-table2-paginator";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {VisitNew} from "./visit_new";
import { VisitlList_Filter } from "./visitList_filter";


class VisitListComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            availSecurityClearanceLevel: ['NONE', 'CONFIDENTIAL', 'SECRET', 'TOP_SECRET'].map(clrLvlStr => {return {value: clrLvlStr, label: clrLvlStr}}),
            page: 1,
            sizePerPage : 10,
            filter:{
                visitingSecurityOfficeName:"",
                securityPOCName:"",
                purpose:"",
                classificationLevel:""
            } 
        };
    }

    componentDidMount() {
        this.getVisits();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.getVisits();
    }

    getVisits = async () => {
        try {
            const {page, sizePerPage} = this.state;
            const visitReqResponse = await fetchVisitsByQuery(page, sizePerPage);
            this.setState({visitResponse: visitReqResponse.data});
        } catch(error){
            console.log('Error is ', error);
            this.props.showErrorGrowl("Unexpected error", "Unable to fetch visits")
        };
    }

    getVisits = async (page, sizePerPage, officeNameFilter, securityPocNameFilter,
                       classificationLevelFilter, purposeFilter, officeNameSort, startDateSort, endDateSort) => {
        try {
            debugger;
            const visitReqResponse = await fetchVisitsByQuery(page, sizePerPage, officeNameFilter, securityPocNameFilter,
                classificationLevelFilter, purposeFilter, officeNameSort, startDateSort, endDateSort);
            console.log('Visits got are ', visitReqResponse);
            this.setState({visitResponse: visitReqResponse.data});
        } catch(error){
            console.log('Error is ', error);
            this.props.showErrorGrowl("Unexpected error", "Unable to fetch visits")
        };
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Visits
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }


    // onTableChange({page, sizePerPage, filters, sortField, sortOrder, cellEdit}) {

    //     console.log('Sortfield is ', sortField)
    //     // Filters
    //     const officeNameFilter = filters.visitingSecurityOfficeName ? filters.visitingSecurityOfficeName.filterVal : "";
    //     const securityPocNameFilter = filters.securityPOCName ? filters.securityPOCName.filterVal : null;
    //     const classificationLevelFilter = filters.classificationLevel ? filters.classificationLevel.filterVal : "";
    //     const purposeFilter = filters.purpose ? filters.purpose.filterVal : "";

    //     // Sort
    //     let officeNameSort = 0;
    //     let startDateSort = 0;
    //     let endDateSort = 0;


    //     const sortDir = sortOrder === 'asc' ? 1 : -1;

    //     switch (sortField) {
    //         case "visitingSecurityOfficeName":
    //             officeNameSort = sortDir;
    //             break;
    //         case "visitStartDate":
    //             startDateSort = sortDir;
    //             break;
    //         case "visitEndDate":
    //             endDateSort = sortDir;
    //             break;
    //     }

    //     // Request Object
    //     this.getVisits(page, sizePerPage, officeNameFilter, securityPocNameFilter,
    //         classificationLevelFilter, purposeFilter, officeNameSort, startDateSort, endDateSort);

    //     this.setState({
    //         page: page,
    //         sizePerPage: sizePerPage
    //     });
    // } 
     
//     onFilterClick = async (filters) => {
//         console.log(filters)
//         let { sizePerPage, Sort, filter } = this.state;

//         filters.securityPocName = filters.securityPocName ? filters.securityPocName : null;
// const  classificationLevel =filters.classificationLevel ? {id:filters.classificationLevel}:null;
//         // Sort
//         let officeNameSort = 0;
//         let startDateSort = 0;
//         let endDateSort = 0;
//         let page = 1;

//         const sortDir = Sort.sortOrder === 'asc' ? 1 : -1;
//         switch (Sort.sortField) {
//             case "visitingSecurityOfficeName":
//                 officeNameSort = sortDir;
//                 break;
//             case "visitStartDate":
//                 startDateSort = sortDir;
//                 break;
//             case "visitEndDate":
//                 endDateSort = sortDir;
//                 break;
//         }

//         this.setState({
//             loading: true
//         });

//         // Request Object

//         this.getVisits(page, sizePerPage, filters.visitingSecurityOfficeName, filters.securityPOCName,
//             classificationLevel, filters.purpose, officeNameSort, startDateSort, endDateSort);

//         this.setState({
//             page: page,
//             sizePerPage: sizePerPage, 
//             filter: filters,
//             loading: false
//         });


//     } 

    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }
    visitFilterby = () => { 
        let { visitResponse, filter } = this.state;  
        let filteredData = visitResponse.visits.filter(item =>
            (filter.visitingSecurityOfficeName === "" || item.visitingSecurityOfficeName.toLowerCase().includes(filter.visitingSecurityOfficeName.toLowerCase())) &&
            (filter.securityPOCName === "" || item.securityPOCName.toLowerCase().includes(filter.securityPOCName.toLowerCase())) &&
            (filter.purpose === "" || item.purpose.toLowerCase().includes(filter.purpose.toLowerCase())) &&
            (filter.classificationLevel === "" || (item.classificationLevel && item.classificationLevel.id === filter.classificationLevel))  
        )
        return filteredData; 
    }

    getColumns() {
        const {availSecurityClearanceLevel} = this.state;

        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className="table_title">
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        };

        const makeLink = (visitId, text) => {
            return text;
            // return <a href="#" onClick={() => this.props.viewDoc(docId)}>{text}</a>
        };

        return [{
            dataField: "visitingSecurityOfficeName",
            text: "Visiting Office Name",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "securityPOCName",
            text: "Security POC Name",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "smoCode",
            text: "SMO Code",
            formatter: (cell, row) => makeLink(row.id, cell),
            // sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: "purpose",
            text: "Purpose",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "classificationLevel",
            text: "Classification Level",
            formatter: (cell, row) => makeLink(row.id, cell? cell.displayName:cell),
            sort: true,
            // filter: selectFilter({
            //     options: availSecurityClearanceLevel
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: "visitStartDate",
            text: "Start Date",
            formatter: (cell, row) => makeLink(row.id, DateUtils.getDateString(cell)),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: "visitEndDate",
            text: "End Date",
            formatter: (cell, row) => makeLink(row.id, DateUtils.getDateString(cell)),
            sort: true,
            headerFormatter: headerFormatter
        }]
    }

    render() {
        const {visitResponse, page, sizePerPage} = this.state;
        if (!visitResponse){
            return <div>Loading...</div>;
        }
        console.log('Visits are ', visitResponse.visits)

        return (
            <Fragment>
                 {/* {this.getBreadCrumb()} */}
                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <VisitNew/>
                        </ButtonToolbar>
                    </div>
                )} 
                <div className="d-flex">
                    <h3 className="page_title_left">Visits</h3>
                    <VisitlList_Filter filters={this.state.filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.visitFilterby()}
                                columns={this.getColumns()}
                                pagination={paginationFactory({page, sizePerPage, totalSize: visitResponse.totalSize})}
                                filter={filterFactory()} 
                                // onTableChange={this.onTableChange}  
                                defaultSorted={[{ dataField: 'visitingSecurityOfficeName', order: 'asc' }]}/>
            </Fragment>
        )
    }
}

export const ApprovedVisitList = connect(null, {showErrorGrowl})(VisitListComponent);