import React from "react";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import MaskedInput from "react-input-mask";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {CommonUtils} from "../../../../actions/common_utils";
import {createVisit, modifyVisit, reportVisit} from "../../../../actions/visit_actions";
import {getOrgSubjectsWithLessFields} from "../../../../actions/subject_actions";
import {VERIFIED_EMAIL} from "../../../../actions/auth_actions";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import {CUR_ORG} from "../../../../actions/session_constants";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {getFacilityLevels} from "../../../../actions/fieldValue_actions";

class VisitNewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            showModal: false,
            availSubjects: []
        }
    }

    componentDidMount() {
        this.getAvailSubjects();
        getFacilityLevels((response) => {
            this.setState({
                availSecurityClearanceLevel: response.data
            });
        });

    }

    getAvailSubjects = async () => {
        try {
            let response = await getOrgSubjectsWithLessFields();
            let subjects = response.data;
            let availSubjects = subjects.map(subject => {
                return {
                    value: subject.id,
                    label: `${subject.personFirstName} ${subject.personLastName} (${subject.socialSecurityNumber})`
                }
            });
            let availSubsMap = new Map();
            for (let eachSub of subjects){
                availSubsMap.set(eachSub.id, eachSub.emailAddressOfCurrentOrg);
            }
            this.setState({
                availSubjects,
                availSubsMap
            })
        } catch (error) {
            console.log('Error getting subjects ', error)
            this.props.showErrorGrowl("Unable to get subjects", error.response.data);
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    async onSubmit(values) {
        let {availSubsMap} = this.state;
        values.subjectEmailList = []
        for (let eachSubjectId of values.subjects){
            values.subjectEmailList.push(availSubsMap.get(eachSubjectId));
        }
        values.orgId = sessionStorage.getItem(CUR_ORG);
        let tempSubs = values.subjects;
        values.subjects = [];
        const {modifySource} = this.props;
        if (modifySource) {
            await this.props.modifyVisit(values, () => {

                // Success Response
                this.props.showSuccessGrowl("Visit Created", "Visit was created successfully");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/org/dash/visits");
            }, (error) => {
                console.log('Error is ', error);
                values.subjects = tempSubs;
                this.props.showErrorGrowl("Unexpected Error", "Unable to create Visit");
            });
        } else {
            try {
                await createVisit(values);
                // Success Response
                this.props.showSuccessGrowl("Visit Reported", "Visit was reported successfully and is under review");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/org/dash/visits");
            } catch (error) {
                console.log('Error is ', error);
                values.subjects = tempSubs;
                this.props.showErrorGrowl("Unexpected Error", "Unable to create Visit");
            }
        }
    }

    initializeEmailAddressIfModify = (visit) => {
        let {otherSubjects} = this.state;
        if (visit.subjects && otherSubjects.length === 0){
            for (let eachSubject of visit.subjects){
                otherSubjects.push(eachSubject);
            }
        }
    }

    render() {
        const {availSecurityClearanceLevel, showModal, orgsToShow, otherSubjects} = this.state;
        const {cloneSource, modifySource} = this.props;

        let visitIssueTempObj = {
            visitingSecurityOfficeName: "",
            visitingSecurityOfficeAddress: "",
            technicalPOCName: "",
            technicalPOCNumber: '',
            securityPOCName: '',
            securityPOCNumber: '',
            smoCode: '',
            classificationLevel: 'NONE',
            purpose: "",
            orgId: '',
            visitStartDate: '',
            visitForSelf: true,
            visitEndDate: '',
            subjects: [],
        };
        if (orgsToShow && orgsToShow.length > 0) {
            visitIssueTempObj.orgId = orgsToShow[0].id;
        }
        if (cloneSource) {
            Object.assign(visitIssueTempObj, cloneSource);
            this.fixEmailAddressesIfNeeded(visitIssueTempObj);
            visitIssueTempObj.visitStartDate = '';
            visitIssueTempObj.visitEndDate = '';
            visitIssueTempObj.visitForSelf = false;
        }
        if (modifySource) {
            Object.assign(visitIssueTempObj, modifySource);
            visitIssueTempObj.changeComments = '';
            visitIssueTempObj.visitForSelf = false;
            if (!visitIssueTempObj.visitEndDate){
                visitIssueTempObj.visitEndDate = '';
            }
            this.fixEmailAddressesIfNeeded(visitIssueTempObj);
            this.initializeEmailAddressIfModify(modifySource);
        }
        return (
            <React.Fragment>
                {!cloneSource && !modifySource &&
                <Button onClick={this.open} variant="primary">Create Visit</Button>}
                {cloneSource &&
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" title={"Clone Visit"} style={{padding: 10}} aria-label="Clone Visit">
                            <i className="fa fa-clone"/>
                        </span>
                </Button>}
                {modifySource &&
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" title={"Modify Visit"} style={{padding: 10}} aria-label="Modify Visit">
                            <i className="fa fa-square-pen"/>
                        </span>
                </Button>}

                <Modal size="lg" show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modifySource ? 'Modify Visit' : 'Create Visit'}</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={visitIssueTempObj}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                subjects: Yup.array().test(
                                    'subject-email-test',
                                    'Please add at least one subject for visit',
                                    function (value) {
                                        return value && value.length > 0;
                                    }
                                ),
                                visitingSecurityOfficeName: Yup.string().required('Enter visiting office name'),
                                visitingSecurityOfficeAddress: Yup.string().required('Address is required'),
                                technicalPOCName: Yup.string().required("Technical Point of Contact Name is required"),
                                technicalPOCNumber: Yup.string().required('Technical Point of Contact phone number is required'),
                                securityPOCName: Yup.string().required('Security Point of Contact Name is required a contact location'),
                                securityPOCNumber: Yup.string().required('Security Point of Contact phone number is required'),
                                classificationLevel: Yup.string().required('Select a classification level'),
                                smoCode: Yup.string().required('Enter a smo code'),
                                purpose: Yup.string().required('Enter a visit reason'),
                                visitStartDate: Yup.date().required('Enter date of visit'),
                                visitEndDate: Yup.date()
                                    .when('visitStartDate', (visitStartDate) => {
                                        if (visitStartDate) {
                                            return Yup.date()
                                                .min(visitStartDate, 'End Date must be after Start Date')
                                        }
                                    }),
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId={'subjects'}>
                                            <Form.Label>Visiting Subjects</Form.Label>
                                            <Field
                                                id="subjects"
                                                name="subjects"
                                                component={FormikFieldDualListBox}
                                                options={this.state.availSubjects}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjects}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="VisitNewName">
                                            <Form.Label>Visiting Security Office Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office name"
                                                isInvalid={touched.visitingSecurityOfficeName && !!errors.visitingSecurityOfficeName}
                                                value={values.visitingSecurityOfficeName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="visitingSecurityOfficeAddress">
                                            <Form.Label>Visiting Security Office Address</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office address"
                                                isInvalid={touched.visitingSecurityOfficeAddress && !!errors.visitingSecurityOfficeAddress}
                                                value={values.visitingSecurityOfficeAddress}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCName">
                                            <Form.Label>Technical POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="technicalPOCName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter technical point of contact name"
                                                isInvalid={touched.technicalPOCName && !!errors.technicalPOCName}
                                                value={values.technicalPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCNumber">
                                            <Form.Label>Technical POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="technicalPOCNumber"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.technicalPOCNumber && !!errors.technicalPOCNumber}
                                                value={values.technicalPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="securityPOCName">
                                            <Form.Label>Security POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="securityPOCName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter security point of contact name"
                                                isInvalid={touched.securityPOCName && !!errors.securityPOCName}
                                                value={values.securityPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="securityPOCNumber">
                                            <Form.Label>Security POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="securityPOCNumber"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.securityPOCNumber && !!errors.securityPOCNumber}
                                                value={values.securityPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={"classificationLevel"}>
                                            <Form.Label>Classification Level</Form.Label>
                                            <Form.Control required as="select" name={'classificationLevel'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.classificationLevel && !!errors.classificationLevel}
                                                          value={values.classificationLevel}
                                            >
                                                {CommonUtils.getOptionsForSelect(availSecurityClearanceLevel)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.classificationLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="smoCode">
                                            <Form.Label>SMO Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="smoCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter SMO Code"
                                                isInvalid={touched.smoCode && !!errors.smoCode}
                                                value={values.smoCode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smoCode}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="purpose">
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="purpose"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Purpose of Visit"
                                                isInvalid={touched.purpose && !!errors.purpose}
                                                value={values.purpose}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.purpose}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit Date</Form.Label>
                                            <Field
                                                id="visitStartDate"
                                                name="visitStartDate"
                                                minDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter the date of Visit"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit End Date</Form.Label>
                                            <Field
                                                id="visitEndDate"
                                                name="visitEndDate"
                                                minDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"End date if visit is for multiple days"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    {modifySource &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="changeComments">
                                            <Form.Label>Change Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="changeComments"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a reason for the change. Ex: Adding additional member"
                                                isInvalid={touched.changeComments && !!errors.changeComments}
                                                value={values.changeComments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.changeComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting}>Create</Button>
                                                   <DeleteConfirmationModal buttonText={"Cancel"}  disabled={isSubmitting} actionText={"cancel"} deleteEntity="Create Visit"
                                                  handleDeleteCallback={this.close}/>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>
        )
    }

    fixEmailAddressesIfNeeded(visitIssueTempObj) {
        if (visitIssueTempObj.subjectEmailList && Array.isArray(visitIssueTempObj.subjectEmailList)) {
            console.log(visitIssueTempObj.subjectEmailList);
            visitIssueTempObj.subjectEmailList = visitIssueTempObj.subjectEmailList.join(",");
        }
    }
}

export const
    VisitNew = withRouter(connect(null, {reportVisit, modifyVisit, showSuccessGrowl, showErrorGrowl})(VisitNewComponent));

VisitNew.propTypes = {
    cloneSource: PropTypes.object,
    modifySource: PropTypes.object,
}