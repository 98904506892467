import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap"; 
import { CommonUtils } from "../../../../actions/common_utils";




export const VisitIssue_Filter = ({ filters, onSubmit }) => {

    const [ShowHover, setShowHover] = useState(false); 
    const popupRef = useRef(null);

    useEffect(()=>{
        const handleClickOutside = (event) => { 
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        }; 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[])

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => { 
        onSubmit({
            visitingSecurityOfficeName:"",
            classificationLevel:"",
            visitStartDate:"",
            visitEndDate:"",
            status:"PENDING"
        })
        setShowHover(false)
    }
    
    const statusOptions = [
        {id: "PENDING", name: "Pending"},
        {id: "REJECTED", name: "Rejected"},
        {id: "APPROVED", name: "Approved"}
    ]

    

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur 
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}> 
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Visiting Office Name</label>
                                        <Form.Control
                                            type="text"
                                            name={"visitingSecurityOfficeName"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Visiting Office Name"}
                                            value={values.visitingSecurityOfficeName}
                                        />
                                    </div> 
                                    <div className="form-group mb-3">
                                        <label className="form-label">Security Classification</label>
                                        <Form.Control
                                            type="text"
                                            name={"classificationLevel"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Security Classification"}
                                            value={values.classificationLevel}
                                        />
                                    </div> 
                                    <div className="form-group mb-3">
                                    <label className="form-label">Visit Start Date</label>
                                        <Form.Control
                                            type="text"
                                            name={"visitStartDate"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Visit Start Date"}
                                            value={values.visitStartDate}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Visit End Date</label>
                                        <Form.Control
                                            type="text"
                                            name={"visitEndDate"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Visit End Date"}
                                            value={values.visitEndDate}
                                        />
                                    </div>
                                  <div className="form-group mb-3">
                                    <label className="form-label">Status</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"status"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.status && !!errors.status
                                            }
                                            value={values.status}
                                        >
                                            {CommonUtils.getOptionsForSelect(statusOptions)}
                                        </Form.Control>
                                    </div> 
                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}