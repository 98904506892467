import React from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import MaskedInput from "react-input-mask";
import {DateUtils} from "../../../../actions/common_utils";
import PropTypes from "prop-types";
import {approveVisit, fetchVisitIssue, rejectVisit} from "../../../../actions/visit_actions";
import Alert from "react-bootstrap/Alert";
import {Card} from "react-bootstrap";

class VisitReviewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        this.props.fetchVisitIssue(this.props.match.params.visitIssueId);
    }

    async onSubmit(values) {
        if (values.rejectionReason) {
            await this.props.rejectVisit(values, this.props.match.params.visitIssueId, () => {

                // Success Response
                this.props.showSuccessGrowl("Visit Rejected", "Visit was rejected and subject notified");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/org/dash/visitRequests");
            }, (error) => {
                console.log('Error rejecting visit ', error);
                this.props.showErrorGrowl("Unexpected Error", "Cannot reject the visit. Unexpected error.");
            });
        } else {
            await this.props.approveVisit(values, this.props.match.params.visitIssueId, () => {

                // Success Response
                this.props.showSuccessGrowl("Visit Approved", "Visit was approved successfully");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/org/dash/visitRequests");
            }, (error) => {
                console.log('Error approving visit ', error);
                this.props.showErrorGrowl("Unexpected Error", "Cannot approve the visit. Unexpected error.");
            });
        }
    }

    formatSubjectNameAndEmail = (visit) => {
        let str = '';
        for (let subjectNameAndEmail of visit.subjectNameAndEmailList){
            str += subjectNameAndEmail + '\n';
        }
        visit.formattedSubjectNameAndEmail = str;
    }
    render() {

        let {visit_issue} = this.props;
        if (!visit_issue) {
            return <div>Loading...</div>;
        }

        visit_issue.visit.rejectionReason = '';
        if (visit_issue.visit.classificationLevel && visit_issue.visit.classificationLevel.id){
            visit_issue.visit.classificationLevel = visit_issue.visit.classificationLevel.id;
        }
        this.formatSubjectNameAndEmail(visit_issue.visit)
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>Visit Details</Card.Header>
                    <Formik
                        initialValues={visit_issue.visit}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                visitingSecurityOfficeName: Yup.string().required('Enter visiting office name'),
                                visitingSecurityOfficeAddress: Yup.string().required('Address is required'),
                                technicalPOCName: Yup.string().required("Technical Point of Contact Name is required"),
                                technicalPOCNumber: Yup.string().required('Technical Point of Contact phone number is required'),
                                smoCode: Yup.string().required('Enter a smo code'),
                                securityPOCName: Yup.string().required('Security Point of Contact Name is required a contact location'),
                                securityPOCNumber: Yup.string().required('Security Point of Contact phone number is required'),
                                classificationLevel: Yup.string().required('Select a classification level')
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="formattedSubjectNameAndEmail">
                                            <Form.Label>Visiting Subjects</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="formattedSubjectNameAndEmail"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.formattedSubjectNameAndEmail && !!errors.formattedSubjectNameAndEmail}
                                                value={values.formattedSubjectNameAndEmail}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.formattedSubjectNameAndEmail}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="VisitNewName">
                                            <Form.Label>Visiting Security Office Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeName"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office name"
                                                isInvalid={touched.visitingSecurityOfficeName && !!errors.visitingSecurityOfficeName}
                                                value={values.visitingSecurityOfficeName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="visitingSecurityOfficeAddress">
                                            <Form.Label>Visiting Security Office Address</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="visitingSecurityOfficeAddress"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter visiting office address"
                                                isInvalid={touched.visitingSecurityOfficeAddress && !!errors.visitingSecurityOfficeAddress}
                                                value={values.visitingSecurityOfficeAddress}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitingSecurityOfficeAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCName">
                                            <Form.Label>Technical POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="technicalPOCName"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter technical point of contact name"
                                                isInvalid={touched.technicalPOCName && !!errors.technicalPOCName}
                                                value={values.technicalPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="technicalPOCNumber">
                                            <Form.Label>Technical POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="technicalPOCNumber"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.technicalPOCNumber && !!errors.technicalPOCNumber}
                                                value={values.technicalPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.technicalPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="securityPOCName">
                                            <Form.Label>Security POC Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="securityPOCName"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter security point of contact name"
                                                isInvalid={touched.securityPOCName && !!errors.securityPOCName}
                                                value={values.securityPOCName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="securityPOCNumber">
                                            <Form.Label>Security POC Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="securityPOCNumber"
                                                mask="999-999-9999"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.securityPOCNumber && !!errors.securityPOCNumber}
                                                value={values.securityPOCNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.securityPOCNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId={"classificationLevel"}>
                                            <Form.Label>Classification Level</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="classificationLevel"
                                                disabled={(visit_issue.status === 'APPROVED')}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter security point of contact name"
                                                isInvalid={touched.classificationLevel && !!errors.classificationLevel}
                                                value={values.classificationLevel}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.classificationLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="smoCode">
                                            <Form.Label>SMO Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="smoCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter SMO Code"
                                                isInvalid={touched.smoCode && !!errors.smoCode}
                                                value={values.smoCode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smoCode}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="8" controlId="purpose">
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="purpose"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.purpose && !!errors.purpose}
                                                value={values.purpose}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.purpose}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit Date</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="visitStartDate"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.visitStartDate && !!errors.visitStartDate}
                                                value={DateUtils.getDateString(values.visitStartDate)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitStartDate}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Visit Date</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="visitEndDate"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.visitEndDate && !!errors.visitEndDate}
                                                value={DateUtils.getDateString(values.visitEndDate)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.visitEndDate}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="changeComments">
                                            <Form.Label>Change Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled
                                                name="changeComments"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.changeComments && !!errors.changeComments}
                                                value={values.changeComments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.changeComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {(visit_issue.status !== 'APPROVED') &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="rejectionReason">
                                            <Form.Label>Rejection Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="rejectionReason"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a rejection reason if rejecting"
                                                isInvalid={touched.rejectionReason && !!errors.rejectionReason}
                                                value={values.rejectionReason}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rejectionReason}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                {(visit_issue.status !== 'APPROVED') &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting || values.rejectionReason}>Approve</Button>
                                        <Button variant="danger" type="submit"
                                                disabled={isSubmitting || !values.rejectionReason}>Reject</Button>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                            </Form>
                        )}
                    </Formik>
                </Card>
            </React.Fragment>
        )
    }
}

function mapStateToProps({visit_issue}) {
    return {
        visit_issue
    }
}

export const
    VisitReview = connect(mapStateToProps, {
        approveVisit,
        rejectVisit,
        showSuccessGrowl,
        showErrorGrowl,
        fetchVisitIssue
    })(VisitReviewComponent);

