import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap"; 
import { CommonUtils } from "../../../../actions/common_utils";




export const VisitlList_Filter = ({ filters, onSubmit }) => {

    const [ShowHover, setShowHover] = useState(false); 
    const popupRef = useRef(null);

    useEffect(()=>{
        const handleClickOutside = (event) => { 
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        }; 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[])

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => { 
        onSubmit({
            visitingSecurityOfficeName:"",
            securityPOCName:"",
            purpose:"",
            classificationLevel:""
        })
        setShowHover(false)
    }
    
    const availSecurityClearanceLevel = [
        {id: "", name: "Select Classification Level"},
        {id: "NONE", name: "NONE"},
        {id: "CONFIDENTIAL", name: "CONFIDENTIAL"},
        {id: "SECRET", name: "SECRET"},
        {id: "TOP_SECRET", name: "TOP_SECRET"} 
    ];

    

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur 
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}> 
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Visiting Office Name</label>
                                        <Form.Control
                                            type="text"
                                            name={"visitingSecurityOfficeName"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Visiting Office Name"}
                                            value={values.visitingSecurityOfficeName}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Security POC Name</label>
                                        <Form.Control
                                            type="text"
                                            name={"securityPOCName"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Security POC Name"}
                                            value={values.securityPOCName}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Purpose</label>
                                        <Form.Control
                                            type="text"
                                            name={"purpose"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Purpose"}
                                            value={values.purpose}
                                        />
                                    </div>
                                  <div className="form-group mb-3">
                                    <label className="form-label">Classification Level</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"classificationLevel"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.classificationLevel && !!errors.classificationLevel
                                            }
                                            value={values.classificationLevel}
                                        >
                                            {CommonUtils.getOptionsForSelect(availSecurityClearanceLevel)}
                                        </Form.Control>
                                    </div> 
                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}