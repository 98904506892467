import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter";
import {connect} from "react-redux";
import {fetchVisitIssueforStatus, fetchVisitIssues} from "../../../../actions/visit_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Picky} from "react-picky";
import {DateUtils} from "../../../../actions/common_utils";
import { VisitIssue_Filter } from "./visitIssue_filter";

class VisitIssueList extends Component {
    constructor(props) {
        super(props);

        this.onTableChange = this.onTableChange.bind(this);

        this.state = {
            selStatus: [{value: "PENDING", label: "Pending"}],
            visit_issues:null,
            filter:{
                visitingSecurityOfficeName:"",
                classificationLevel:"",
                visitStartDate:"",
                visitEndDate:"",
                status:"PENDING"
            }
        }
    }

    componentDidMount() {
        // this.props.fetchVisitIssues('PENDING');
       this.getVisitIssueList('PENDING');
    }

    getVisitIssueList = async (status)=>{ 
        let visitissues = await fetchVisitIssueforStatus(status) 
        this.setState({ visit_issues: visitissues })
    }

    onTableChange(type, newState) {
        switch (type) {
            case "filter":
                const filters = newState.filters;
                console.log(filters);
                let issueStatus = "PENDING";
                if (filters.status) {
                    issueStatus = filters.status.filterVal;
                }
                this.props.fetchVisitIssues(issueStatus);
                break;
        }
    }   
    
    onFilterClick = async (values) => {
        console.log(values)
        this.getVisitIssueList(values.status);
        this.setState({
            filter: values
        })
    }

    compareFilter = (type, object, filter) => {
        if(!object) return false;
        if (type === "text") {
            return (filter === "" || String(object).toLowerCase().includes(filter.toLowerCase()))
        }
        else if (type === "date") {
            console.log(DateUtils.getDateString(object))
            return (filter === "" || DateUtils.getDateString(object) === filter)
        }
    }

    visitIssueFilterby = () => { 
        let { visit_issues, filter } = this.state;  
        let filteredData = visit_issues && visit_issues.filter(item =>
            this.compareFilter("text", item.visit.visitingSecurityOfficeName, filter.visitingSecurityOfficeName) &&  
            (filter.classificationLevel === "" || (item.visit.classificationLevel && item.visit.classificationLevel.displayName.toLowerCase().includes(filter.classificationLevel.toLowerCase()))) &&
            this.compareFilter("date", item.visit.visitEndDate, filter.visitEndDate) &&  
            this.compareFilter("date", item.visit.visitStartDate, filter.visitStartDate)
             
            // (filter.visitingSecurityOfficeName === "" || item.visit.visitingSecurityOfficeName.toLowerCase().includes(filter.visitingSecurityOfficeName.toLowerCase())) &&
            // (filter.visitStartDate === "" || item.visit.visitStartDate.includes(filter.visitStartDate)) &&
            // (filter.visitEndDate === "" || item.visit.visitEndDate.includes(filter.visitEndDate)) &&
            // (filter.classificationLevel === "" || (item.visit.classificationLevel && item.visit.classificationLevel.displayName.toLowerCase().includes(filter.classificationLevel)))
        )
        return filteredData; 
    }

    getCellLink = (row, cellData) => {
        return (
            <Link to={`/org/dash/visitRequest/${row.id}`}>{cellData}</Link>
        );
    }

    getStatusCellValue(cell){
        return cell.substr(0,1).toUpperCase() + cell.substr(1).toLowerCase();
    }
    getColumns() {
        // const statusOptions = [
        //     {value: "PENDING", label: "Pending"},
        //     {value: "REJECTED", label: "Rejected"},
        //     {value: "APPROVED", label: "Approved"}
        // ]

        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'visit.visitingSecurityOfficeName',
            text: 'Visiting Office Name',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'visit.classificationLevel',
            text: 'Security Classification',
            formatter: (cell, row) => this.getCellLink(row, (cell && cell.displayName?cell.displayName: cell)),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'visit.visitStartDate',
            text: 'Visit Start Date',
            formatter: (cell, row) => this.getCellLink(row, (cell? DateUtils.getDateString(cell): cell)),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'visit.visitEndDate',
            text: 'Visit End Date',
            formatter: (cell, row) => this.getCellLink(row, (cell? DateUtils.getDateString(cell): cell)),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "visit.smoCode",
            text: "SMO Code",
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: (cell, row) => this.getCellLink(row, this.getStatusCellValue(cell)),
            sort: true,
            // filter: selectFilter({
            //     options: statusOptions,
            //     defaultValue: 'PENDING'
            // }),
            headerFormatter: headerFormatter
        }];
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Visit Requests
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        // let {visit_issues} = this.props;
        let {visit_issues} = this.state;
 
        if (!visit_issues) {
            return <div>Loading...</div>
        }

        const remoteSettings = {
            filter: true,
            pagination: false,
            sort: false,
            cellEdit: false
        };

        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */} 
                <div className="d-flex">
                    <h3 className="page_title_left">Visit Requests</h3>
                    <VisitIssue_Filter filters={this.state.filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.visitIssueFilterby()}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                onTableChange={this.onTableChange}/>
            </Fragment>
        )
    }
}

function mapStateToProps({visit_issues}) {
    return {
        visit_issues
    }
}

export default connect(mapStateToProps, {fetchVisitIssues})(VisitIssueList);